<template>
  <div class="course-box">
    <div class="operate-box">
      <el-button type="primary" style="margin-bottom: 20px" @click="addBtn('add', null, null)">创建试题</el-button>
    </div>
    <el-table :data="list" style="width: 100%; flex: 1" height="1%" class="customTable"
              :header-cell-style="{ fontWeight: 'normal', color: '#332D42', background: '#F6F6F6', height: '60px' }"
              :cell-style="{ fontSize: '14px', color: '#332D42', height: '70px' }">
      <el-table-column prop="name" label="任务名称" show-overflow-tooltip min-width="300"></el-table-column>
      <el-table-column label="任务状态">
        <template #default="scope">
          {{formatTime(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="number" label="班级人数"></el-table-column>
      <el-table-column prop="create" label="创建时间" width="150"></el-table-column>
      <el-table-column prop="begin" label="开始时间" width="150"></el-table-column>
      <el-table-column prop="end" label="结束时间" width="150"></el-table-column>
      <el-table-column label="操作" align="right" width="200">
        <template #default="scope">
          <el-link :underline="false" type="primary" v-if="formatTime(scope.row) === '已结束'" @click="toCheck(scope.row)">批阅</el-link>
          <el-link :underline="false" type="primary" @click="lookDetail(scope.row, scope.$index)">查看</el-link>
          <el-link :underline="false" type="primary" @click="addBtn('edit', scope.row, scope.$index)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="delBtn(scope.$index)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        style="padding-bottom: 30px"
        class="pages-center"
        layout="prev, pager, next, jumper"
        :total="list.length">
    </el-pagination>

    <el-dialog title="创建试题" :visible.sync="dialogFormVisible" width="615" @close="resetForm('ruleForm')">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="任务名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="默认显示文件名称，字数限制2-18字"></el-input>
        </el-form-item>
        <el-form-item label="任务类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择" style="width: 100%">
            <el-option label="图文" :value="1"></el-option>
            <el-option label="视频" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="素材要求" prop="fileName">
<!--          https://jsonplaceholder.typicode.com/posts/-->
          <el-upload
              class="upload-demo"
              action=""
              :on-success="handleAvatarSuccess"
              :on-remove="handleRemove"
              :on-error="handleAvatarError"
              :before-upload="beforeAvatarUpload"
              accept=".zip, .rar, .pdf"
              :file-list="fileList"
          >
            <div class="flex align-center">
              <el-button size="small" icon="el-icon-upload2">上传附件</el-button>
              <div style="margin-left: 10px; text-align: left; line-height: 1.5">{{ruleForm.fileName}}</div>
            </div>
            <div slot="tip" style="color: #999; font-size: 12px">文件支持格式为zip、rar、pdf</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="任务时间" prop="time">
          <el-date-picker
              style="width: 100%"
              :picker-options="pickerOptions"
              v-model="ruleForm.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="班级" prop="class">
          <el-select v-model="ruleForm.class" placeholder="请选择" style="width: 100%">
            <el-option :label="item.banji_name" :value="item.banji_id" v-for="(item, index) in classList" :key="`classList_${index}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="score">
          <el-input-number v-model="ruleForm.score" :min="1" :max="100" label="描述文字"
                           :controls="false" :precision="0" class="input-number-left" style="width: 100%"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClassListData } from "@/utils/apis";
import moment from "moment";
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          name: '美妆达人秀：化妆技巧分享与交流',
          number: 20,
          create: '2023-03-24 11:33:18',
          begin: '2023-03-31 10:00:00',
          end: '2023-03-31 16:00:00',
          type: 1,
          fileName: '脚本.rar',
          time: ['2023-03-31 10:00:00', '2023-03-31 16:00:00'],
          class: 71,
          score: 50,
        },
        {
          id: 2,
          name: '智能家居新品发布会：家电科技改变生活',
          number: 100,
          create: '2023-04-10 19:33:08',
          begin: '2024-04-11 10:00:00',
          end: '2024-04-12 16:00:00',
          type: 2,
          fileName: '脚本.rar',
          time: ['2024-04-11 10:00:00', '2024-04-12 16:00:00'],
          class: 90,
          score: 80,
        },
        {
          id: 3,
          name: '步步高升：探索鞋靴时尚潮流',
          number: 100,
          create: '2024-04-14 16:54:17',
          begin: '2024-04-15 10:00:00',
          end: '2024-04-30 18:00:00',
          type: 2,
          fileName: '脚本.rar',
          time: ['2024-04-15 10:00:00', '2024-04-30 18:00:00'],
          class: 71,
          score: 80,
        },
        {
          id: 4,
          name: '家电购物节：狂欢价、新品抢先知',
          number: 20,
          create: '2024-04-14 16:54:17',
          begin: '2024-04-30 10:00:00',
          end: '2023-05-31 10:00:00',
          type: 2,
          fileName: '脚本.rar',
          time: ['2024-04-30 10:00:00', '2023-05-31 10:00:00'],
          class: 90,
          score: 80,
        },
      ],

      classList: [],
      currentIndex: null,
      dialogFormVisible: false,
      ruleForm: {
        name: '',
        type: '',
        fileName: '',
        time: [],
        class: '',
        score: void 0,
      },
      rules: {
        name: [
          { required: true, message: '请输入资源标题', trigger: 'blur' },
          { min: 1, max: 18, message: '默认显示文件名称，字数限制2-18字', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择作业类型', trigger: 'change' }
        ],
        class: [
          { required: true, message: '请选择班级', trigger: 'change' }
        ],
        time: [
          { required: true, message: '请选择作业时间', trigger: 'change' }
        ],
        score: [
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      fileList: []
    }
  },
  mounted() {
    this.getClassList()
  },
  methods: {
    formatTime(val) {
      if (moment(val.begin).unix() > moment().unix()) {
        return '未开始'
      } else if (moment().unix() > moment(val.end).unix()) {
        return '已结束'
      } else if (moment(val.begin).unix() <= moment().unix() && moment().unix() <= moment(val.end).unix()) {
        return '进行中'
      }
    },
    async getClassList() {
      let params = {
        paging: 0,
      }
      const res = await getClassListData(params);
      this.classList = res.data.list;
    },
    lookDetail(data, index) {
      this.$router.push({
        path: '/teacher/work/details',
        query: {
          id: index
        }
      })
    },
    addBtn(type, val, idx) {
      this.dialogFormVisible = true
      if (val) {
        this.ruleForm = val
      }
      if (idx > -1) {
        this.currentIndex = idx
      }
      let obj = {
        name: val.fileName,
        url: ''
      }
      this.fileList.splice(0, 1, obj)
    },
    toCheck(val) {
      this.$router.push({
        path: '/teacher/work/check',
        query: {
          id: val.id
        }
      })
    },

    handleAvatarSuccess(res, file) {
      this.ruleForm.fileName = file.name
      let obj = {
        name: file.name,
        url: ''
      }
      this.fileList.splice(0, 1, obj)
    },
    handleAvatarError(err, file) {
      this.ruleForm.fileName = file.name
      let obj = {
        name: file.name,
        url: ''
      }
      this.fileList.splice(0, 1, obj)
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传附件大小不能超过 10MB!');
      }
      return isLt10M;
    },
    handleRemove(file, fileList) {
      this.ruleForm.fileName = ''
      this.fileList = []
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            id: this.list.length + 1,
            name: this.ruleForm.name,
            type: this.ruleForm.type,
            fileName: this.ruleForm.fileName,
            time: this.ruleForm.time,
            class: this.ruleForm.class,
            score: this.ruleForm.score,
            number: this.classList.find(item => item.banji_id === this.ruleForm.class).student_num,
            create: moment().format('YYYY-MM-DD HH:mm:SS'),
            begin: moment(this.ruleForm.time[0]).format('YYYY-MM-DD HH:mm:SS'),
            end: moment(this.ruleForm.time[1]).format('YYYY-MM-DD HH:mm:SS')
          }
          if (this.currentIndex === null){
            this.list.push(obj)
          } else {
            this.list.splice(this.currentIndex, 1, obj)
          }
          this.$message.success('添加成功')
          this.resetForm(formName)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false
      this.currentIndex = null
      this.ruleForm = {
        name: '',
        type: '',
        fileName: '',
        time: [],
        class: '',
        score: void 0,
      }
      this.fileList = []
    },
    delBtn(index) {
      this.$confirm('此操作将永久删除该作业, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.list.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
.course-box {
  padding: 30px 30px 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .operate-box {
    text-align: right;
  }
}
::v-deep .customTable {
  .el-table .cell div {
    display: inline-block;
  }
  .has-gutter .el-table__cell {
    color: #332d42 !important;
    background-color: #f0f3fc !important;
  }
  .cell {
    //line-height: normal;
    //white-space: nowrap;
  }
  .el-table__empty-block {
    display: none;
  }
}
</style>